import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {Banner,mapToPageHeader} from "@components/Banner"
import {SEO,mapToSEO} from '@components/SEO'
import {H2, Paragraph1} from '@styles/Global.styles'
import { SignupBannerWrapper,mapToKiwiVIPVisible} from "@components/SignupBanner";
import {GenericBannerContainer} from '@components/GenericBannerContainer'
import {DualImageCards,mapToDualImageCards} from '@components/DualImageCards'
import { Grid } from "semantic-ui-react"


const TipsTricksPage = (props:any) => {

    const {elements} = props.data.kontentItemTipsTricksPage;

    const seo = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const metadata = mapToPageHeader(elements);
    const heading = elements.heading.value;
    const introHtml = elements.introduction.value;
    const contentTitle = elements.content_title.value;
    const content = elements.content.value;
    const imageCardData = mapToDualImageCards(elements.dual_image_card.linked_items[0].elements);
    return (<Layout location={props.location}>              
        <SEO {...seo} />
        <Banner {...metadata} isBiggerBanner={false} />
        <GenericBannerContainer
          padding={{
            mobile: {
                top: 60,
                bottom: 60
            },
            desktop: {
                top: 80,
                bottom: 80
            }
          }}>
          <Grid>
            <Grid.Column textAlign='center'>
              <H2 textAlign="center">{heading}</H2>
              <Paragraph1 id="___gatsby" dangerouslySetInnerHTML={{__html: introHtml}} />
            </Grid.Column>
          </Grid>
        </GenericBannerContainer>
        <DualImageCards backgroundColor={imageCardData.backgroundColor} card1={imageCardData.card1} card2={imageCardData.card2}/>
        <GenericBannerContainer
          padding={{
            mobile: {
                top: 60,
                bottom: 60
            },
            desktop: {
                top: 80,
                bottom: 80
            }
          }}>
            <H2 textAlign="center">{contentTitle}</H2>
            <Paragraph1 id="___gatsby" dangerouslySetInnerHTML={{__html: content}} />
        </GenericBannerContainer>
        {
            kiwiVip.visible && 
            <SignupBannerWrapper version= {kiwiVip.version} />
        }
  </Layout>);
  
}

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemTipsTricksPage {
    elements {
      heading {
        value
      }
      introduction {
        value
      }
      content_title {
        value
      }
      content {
        value
      }
      dual_image_card {
        linked_items {
          ... on KontentItemDualImageCards {
            elements {
              background_colors {
                value {
                  codename
                }
              }
              image1 {
                value {
                  url
                  description
                }
              }
              title1 {
                value
              }
              description1 {
                value
              }
              image2 {
                value {
                  url
                  description
                }
              }
              title2 {
                value
              }
              description2 {
                value
              }
            }
          }
        }
      }
      seo__noindex {
        value {
          name
          codename
        }
      }
      seo__nofollow {
        value {
          name
          codename
        }
      }
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      general_page_content__title {
        value
      }
      general_page_content__header_image {
        value {
          url
          description
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      general_page_content__kiwivip_signup {
        value {
          name
          codename
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          name
          codename
        }
      }
      general_page_content__title_position {
        value {
          codename
        }
      }
      url {
        value
      }
    }
  }
}


`
export default TipsTricksPage;
