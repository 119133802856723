import React from 'react'
import { Card, Image } from 'semantic-ui-react'
import {
    CardContainer,
    ImageCardContent,
    ImageCardTitle,
    ImageCardDescription
} from './DualImageCards.styles'

export type ImageCardProp = {
    image:{
        url: string
        description?: string
    }
    title:string
    description: string
}

const ImageCard = ({image,title,description}:ImageCardProp) =>{
    return (
        <CardContainer fluid>
            <Image src={image.url} alt={image.description ? image.description : '...'} />
            <ImageCardContent>
                <Card.Header>
                    <ImageCardTitle>{title}</ImageCardTitle>
                </Card.Header>
                <ImageCardDescription id="___gatsby" dangerouslySetInnerHTML={{__html: description}} />
            </ImageCardContent>
        </CardContainer>
    )
}

export default ImageCard