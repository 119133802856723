import {convertKontentColorToHex} from '@utils/Helpers'
import { KenticoChoice, KenticoImage } from '@utils/KontentTypes';

interface DualImageCardProps{
    background_colors:{
        value: KenticoChoice[]
    }
    image1:{
        value: KenticoImage[]
    }
    title1:{
        value:string
    } 
    description1:{
        value:string
    }
    image2:{
        value: KenticoImage[]
    }
    title2:{
        value:string
    } 
    description2:{
        value:string
    }
}

export const mapToDualImageCards = (data:DualImageCardProps) =>{
    const {background_colors, image1, title1, description1, image2, title2, description2} = data;
    const backgroundColor = convertKontentColorToHex(background_colors.value[0].codename);
    const leftImage = image1.value[0];
    const rightImage = image2.value[0];
    return {
        backgroundColor: backgroundColor,
        card1: {
            image: leftImage,
            title: title1.value,
            description: description1.value
        },
        card2:{
            image: rightImage,
            title: title2.value,
            description: description2.value
        }
    };
}