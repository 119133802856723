import styled from '@emotion/styled'
import { CardDescription, CardContent,Card } from 'semantic-ui-react'
import { H5 } from '@styles/Global.styles'
import mq from '@styles/mq'

export const CardContainer = styled(Card)`
    height: 100% !important;
`

export const ImageCardContent = styled(CardContent)`
    &&&&{
        ${mq({
            padding: ['20px', '40px', '40px']
        })}
    }
`

export const ImageCardTitle = styled(H5)`

`

export const ImageCardDescription = styled(CardDescription)`
    margin-top: 20px !important;
`