import React from 'react'
import { GenericBannerContainer } from '@components/GenericBannerContainer'
import { Grid } from 'semantic-ui-react'
import { ImageCard } from './index'
import { ImageCardProp } from './ImageCard'
interface DualImageCardProps {
    backgroundColor: string | string[]
    card1: ImageCardProp
    card2: ImageCardProp
}


const DualImageCards = ({ backgroundColor, card1, card2 }: DualImageCardProps) => {
    return (
        <GenericBannerContainer
            padding={{
                mobile: {
                    top: 60,
                    bottom: 60
                },
                desktop: {
                    top: 80,
                    bottom: 80
                }
            }}
            backgroundColor={backgroundColor}>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column floated="right" width={6}>
                        <ImageCard {...card1} />
                    </Grid.Column>
                    <Grid.Column floated="left" width={6}>
                        <ImageCard {...card2} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </GenericBannerContainer>
    )
}

export default DualImageCards